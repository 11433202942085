import {
	Autocomplete,
	Box,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	TextField,
	Typography
} from "@mui/material";
import React, { FC, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import NumberInput from "../../../../components/NumberInput";
import { CUSTOMER_ROLE_E, CUSTOMER_ROLES_LIST } from "../../../../utils/constants";
import { ISocietyUserRowDetails } from "../UserDetails";
import { useCreateSocietyUserMutation, useEditSocietyUserMutation } from "../../../../redux/reducers/customers.reducer";
import { ISocietyConfigDetails } from "../../../../types";
import { LoadingButton } from "@mui/lab";

interface ICustomerUserDetailsDialogProps {
	open: boolean;
	userDetails: ISocietyUserRowDetails | null;
	societyDetails: ISocietyConfigDetails | null;
	onClose: () => void;
}

interface ICustomerUserDetailsFormFields {
	first_name: string;
	last_name: string;
	login_id: string;
	password: string;
	email: string;
	contact_number: string;
	designation: string;
	role: number;
	address: string;
	allow_secure_login: boolean;
}

const CustomerUserDetailsDialog: FC<ICustomerUserDetailsDialogProps> = (props) => {
	const { open, userDetails, societyDetails, onClose } = props;

	const {
		reset,
		control,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<ICustomerUserDetailsFormFields>();

	// APIS
	const [
		createSocietyUser,
		{
			isLoading: createSocietyUserLoading,
			isSuccess: createSocietyUserSuccess,
			error: createSocietyUserError,
			reset: resetCreateSocietyUserState
		}
	] = useCreateSocietyUserMutation();

	const [
		editSocietyUser,
		{
			isLoading: editSocietyUserLoading,
			isSuccess: editSocietyUserSuccess,
			error: editSocietyUserError,
			reset: resetEditSocietyUserState
		}
	] = useEditSocietyUserMutation();

	function handleSubmitForm(formData: ICustomerUserDetailsFormFields) {
		if (societyDetails) {
			if (userDetails) {
				editSocietyUser({
					id: userDetails.id,
					data: {
						username: formData.login_id,
						first_name: formData.first_name,
						last_name: formData.last_name,
						address: formData.address,
						phone_number: formData.contact_number,
						role: formData.role,
						society: [societyDetails.id],
						designation: formData.designation,
						email: formData.email,
						allow_secure_login: formData.allow_secure_login
					}
				});
			} else {
				createSocietyUser({
					username: formData.login_id,
					first_name: formData.first_name,
					last_name: formData.last_name,
					phone_number: formData.contact_number,
					role: formData.role,
					society: [societyDetails.id],
					designation: formData.designation,
					password: formData.password,
					email: formData.email,
					address: formData.address,
					allow_secure_login: formData.allow_secure_login
				});
			}
		}
	}

	useEffect(() => {
		if (open) {
			if (userDetails) {
				reset({
					first_name: userDetails.first_name,
					last_name: userDetails.last_name,
					contact_number: userDetails.contact_number,
					designation: userDetails.designation,
					email: userDetails.email,
					login_id: userDetails.login_id,
					password: "",
					role: userDetails.role,
					address: userDetails.address,
					allow_secure_login: userDetails.allow_secure_login ?? false
				});
			} else {
				reset({
					first_name: "",
					last_name: "",
					contact_number: "",
					designation: "",
					email: "",
					login_id: "",
					password: "",
					role: CUSTOMER_ROLE_E.TENANT,
					address: "",
					allow_secure_login: false
				});
			}
		} else {
			resetCreateSocietyUserState();
			resetEditSocietyUserState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, reset, userDetails]);

	useEffect(() => {
		if (createSocietyUserSuccess) {
			onClose();
		}
	}, [createSocietyUserSuccess, onClose]);

	useEffect(() => {
		if (editSocietyUserSuccess) {
			onClose();
		}
	}, [editSocietyUserSuccess, onClose]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				User Details
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Box component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="first-name-input"
								error={!!errors.first_name}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								First Name
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="first-name-input"
								placeholder="Enter the First Name"
								error={!!errors.first_name}
								helperText={errors.first_name && errors.first_name.message ? errors.first_name.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("first_name", { required: "First name is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="last-name-input"
								error={!!errors.last_name}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Last Name
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="last-name-input"
								placeholder="Enter the Last Name"
								error={!!errors.last_name}
								helperText={errors.last_name && errors.last_name.message ? errors.last_name.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("last_name", { required: "Last name is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="login-id-input"
								error={!!errors.login_id}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Login ID
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="login-id-input"
								placeholder="Enter the Login ID"
								error={!!errors.login_id}
								helperText={errors.login_id && errors.login_id.message ? errors.login_id.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("login_id", {
									required: "Login ID is required",
									validate: (value) => {
										if (value.includes(" ")) {
											return "Enter a valid login ID";
										}

										return true;
									}
								})}
							/>
						</Grid>

						{!userDetails ? (
							<Grid item xs={12} md={6}>
								<InputLabel
									required
									htmlFor="password-input"
									error={!!errors.password}
									sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
								>
									Password
								</InputLabel>

								<TextField
									required
									fullWidth
									size="small"
									variant="filled"
									id="password-input"
									type="password"
									placeholder="Enter the Password"
									error={!!errors.password}
									helperText={errors.password && errors.password.message ? errors.password.message.toString() : ""}
									InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
									{...register("password", { required: "Password is required" })}
								/>
							</Grid>
						) : null}

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="email-input"
								error={!!errors.email}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Email
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="email-input"
								placeholder="Enter the Email"
								error={!!errors.email}
								helperText={errors.email && errors.email.message ? errors.email.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("email", { required: "Email is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="contact-number-input"
								error={!!errors.contact_number}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Contact Number
							</InputLabel>

							<NumberInput
								isContactNumber
								positiveOnly
								disableSigned
								disableDecimal
								required
								fullWidth
								size="small"
								variant="filled"
								id="contact-number-input"
								placeholder="Enter the Contact Number"
								error={!!errors.contact_number}
								helperText={
									errors.contact_number && errors.contact_number.message ? errors.contact_number.message.toString() : ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("contact_number", { required: "Contact number is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="role-input"
								error={!!errors.role}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Role
							</InputLabel>

							{/* <TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="role-input"
								placeholder="Enter the Role"
								error={!!errors.role}
								helperText={errors.role && errors.role.message ? errors.role.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("role", { required: "Role is required" })}
							/> */}

							<Controller
								name="role"
								control={control}
								defaultValue={0}
								rules={{ required: "Role is required" }}
								render={({ field: { value, onChange } }) => (
									<Autocomplete
										fullWidth
										options={CUSTOMER_ROLES_LIST.filter((item) => item.key !== CUSTOMER_ROLE_E.ADMIN)}
										getOptionLabel={(option) => option.label}
										getOptionKey={(option) => option.key}
										value={CUSTOMER_ROLES_LIST.find((item) => item.key === value) ?? null}
										onChange={(_, updatedValue) => onChange(updatedValue?.key ?? null)}
										id="role-input"
										renderInput={(params) => (
											<TextField
												{...params}
												required
												fullWidth
												hiddenLabel
												size="small"
												variant="filled"
												placeholder="Enter the Role"
												error={!!errors.role}
												helperText={errors.role && errors.role.message ? errors.role.message.toString() : ""}
												InputProps={{
													...params.InputProps,
													hiddenLabel: true,
													disableUnderline: true,
													classes: { root: "filled-input-root" }
												}}
											/>
										)}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="designation-input"
								error={!!errors.designation}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Designation
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="designation-input"
								placeholder="Enter the Designation"
								error={!!errors.designation}
								helperText={
									errors.designation && errors.designation.message ? errors.designation.message.toString() : ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("designation", { required: "Designation is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="address-input"
								error={!!errors.address}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Address
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="address-input"
								placeholder="Enter the Address"
								error={!!errors.address}
								helperText={errors.address && errors.address.message ? errors.address.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("address", { required: "Address is required" })}
							/>
						</Grid>

						{societyDetails && societyDetails.enable_secure_login ? (
							<Grid item xs={12} md={6}>
								<Box sx={{ display: "flex", alignItems: "flex-end", width: "100%", height: "100%" }}>
									<Controller
										control={control}
										name="allow_secure_login"
										render={({ field: { value, onChange } }) => (
											<FormControl>
												<FormControlLabel
													label="Allow secure login"
													control={<Checkbox checked={value} onChange={(_event, checked) => onChange(checked)} />}
												/>
											</FormControl>
										)}
									/>
								</Box>
							</Grid>
						) : null}

						{createSocietyUserError ? (
							<Grid item xs={12}>
								<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
									{"data" in createSocietyUserError && createSocietyUserError.data
										? `${String(createSocietyUserError.data).charAt(0).toUpperCase()}${String(
												createSocietyUserError.data
										  ).slice(1)}`
										: "Failed to add user. Please try again later"}
								</Typography>
							</Grid>
						) : null}

						{editSocietyUserError ? (
							<Grid item xs={12}>
								<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
									{"data" in editSocietyUserError && editSocietyUserError.data
										? `${String(editSocietyUserError.data).charAt(0).toUpperCase()}${String(
												editSocietyUserError.data
										  ).slice(1)}`
										: "Failed to edit user. Please try again later"}
								</Typography>
							</Grid>
						) : null}

						<Grid item xs={12}>
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<LoadingButton
									disableElevation
									variant="contained"
									color="success"
									sx={{ paddingX: 4 }}
									type="submit"
									loading={userDetails ? editSocietyUserLoading : createSocietyUserLoading}
								>
									Save
								</LoadingButton>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default CustomerUserDetailsDialog;
